<template>
  <v-dialog
    v-model="content"
    ref="content"
    height="100%"
    width="100%"
  >
    <div class="donors procon" v-if="content && project">
      <v-icon
        color="black"
        @mouseover="icon = true"
        @mouseout="icon = false"
        style="
          background-color: #5a5f61;
          border-radius: 50%;
          color: #fff !important;
        "
        text
        @click="$emit('close')"
      >
        {{ icon ? "mdi-close" : "mdi-minus" }}
      </v-icon>
      <div class="procontent donors-main">
        <div class="donor-div">
          <div class="pro-options-sub-menu" :class="[type === 'act' ? 'acti' : '']">
              <div class="pro-options-content" v-if="type == 'intro'">
                <p v-html="project[0].introduction"></p>
              </div>
              <div class="pro-options-content" v-else-if="type == 'sdg'">
                <p v-html="project[0].sdg" class="text-white"></p>
              </div>
              <div class="pro-act pro-act-new my-5" v-else-if="type == 'act'">
                <p v-html="project[0].activities"></p>
              </div>
              <div class="pro-options-content" v-else-if="type == 'work'">
                <div v-for="(area, sl) in project[0].working_areas" :key="sl">
                  <p v-html="area.name"></p>
                </div>
              </div>
              <div class="pro-options-content" :class="{'cont-height' : project[0].introduction }" v-else>
                
                <div class="row result-outcome justify-content-center mr-1">
                  <div class="col-12 col-md-4 col-6 px-0 pr-5 pb-3" v-if="project[0].out_reach && project[0].out_reach > 0">
                <div class="outcome-content">
                  <p class="number">
                    {{ Number(project[0].out_reach).toLocaleString() }}
                    </p>
                  <p class="title">Reach</p>
                  <p class="ext-title">(Number of People and Enterprises)</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-6 px-0 pr-5 pb-3" v-if="project[0].beneficiry && project[0].beneficiry > 0">
                <div class="outcome-content">
                    <p class="number">
                      {{ Number(project[0].beneficiry).toLocaleString() }}
                    </p>
                    <p class="title">Beneficiaries</p>
                    <p class="ext-title">(Number of People)</p>
                </div>
              </div>

              
              <div class="col-12 col-md-4 col-6 px-0 pr-5 pb-3" v-if="project[0].beneficiary_enterprise && project[0].beneficiary_enterprise > 0">
                <div class="outcome-content">
                <p class="number">
                  {{ Number(project[0].beneficiary_enterprise).toLocaleString() }}
                  </p>
                  <p class="title">Beneficiaries</p>
                    <p class="ext-title">(Number of Enterprises)</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-6 px-0 pr-5 pb-3" v-if="project[0].partner && project[0].partner > 0">
                <div class="outcome-content">
                  <p class="number">
                    {{ Number(project[0].partner).toLocaleString() }}
                    </p>
                  <p class="title">Partners</p>
                  <p class="ext-title">(Number of Public and Private Organisations)</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-6 px-0 pr-5 pb-3" v-if="project[0].income_generated && project[0].income_generated > 0">
                <div class="outcome-content">
                  <p class="number">
                    {{ Number(project[0].income_generated).toLocaleString() }}
                    </p>
                  <p class="title">Income Generated</p>
                    <p class="ext-title">(In CHF)</p>
                </div>
              </div>
              <div class="col-md-4 col-6 px-0 pb-3" v-if="project[0].private_sector_fund && project[0].private_sector_fund > 0">
                <div class="outcome-content">
                  <p class="number">
                    {{ Number(project[0].private_sector_fund).toLocaleString() }}
                    </p>
                  <p class="title">Private Sector funding</p>
                  <p class="ext-title">(In CHF)</p>
                </div>
              </div>
              <div class="col-md-12 col-12 px-0 pr-5 pb-3" v-if="project[0].introduction">
                <div class="outcome-content p-3">
                  <p class="other-details">{{project[0].introduction}}</p>
                  </div>
              </div>
              </div>
            </div>
              </div>
            </div>
          </div>
      </div>
  </v-dialog>
</template>

<script>
export default {
  name: "procontent",
  data: () => ({
    donors: false,
    beneficiary: false,
    partner: false,
    organizer: false,
    newContent: false,
    newtype: "",
    icon: false,
  }),
  components: {
    number: () => import("../components/number"),
  },
  props: {
    content: false,
    project: {},
    color: null,
    type: "",
  },
  methods: {
    setprojectview: function (event) {
      let type = event.currentTarget.id;
      if (type == "donor") {
        this.donors = !this.donors;
        this.partner = false;
        this.beneficiary = false;
        this.organizer = false;
        console.log("donors true korechi ");
      } else if (type == "partner") {
        this.donors = false;
        this.partner = !this.partner;
        this.beneficiary = false;
        this.organizer = false;
        console.log("partner true korechi");
      } else if (type == "organizer") {
        this.donors = false;
        this.partner = false;
        this.beneficiary = false;
        this.organizer = !this.organizer;
      } else {
        this.donors = false;
        this.partner = false;
        this.beneficiary = !this.beneficiary;
        this.organizer = false;
      }
    },
    setactive: function (type) {
      console.log("new type peyechi", type);
      this.newtype = type;
      //   if(index == 0){
      //     type = "intro"
      //   }
      //   else if(index == 1){
      //     type = "sdg"
      //   }
      //   else if(index == 2){
      //     type = "act"
      //   }
      //   else if(index == 4){
      //     type = "work"
      //   }
      //   else{
      //     type = "res"
      //   }
      this.newContent = !this.newContent;
    },
  },
};
</script>

<style scoped>
.pro-options-content p {
  font-family: "Calibri", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  text-align: justify;
  color: #5a5f61;
}
.pro-options-sub-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: flex-start; */
  /* padding: 50px; */
  gap: 70px;
  position: absolute;
  width: 90%;
  height: 100%;
  top:0;
  bottom:0;
  overflow-y: auto;
  overflow-x:hidden;
  padding:0 10px;
  left: 0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  margin-top:auto;
  margin-bottom:auto;

  /* top: 20%; */
  background: transparent;
  border-radius: 10px;
}

.outcome-content {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* left: 188.21px; */
  /* top: 248.04px; */
  padding: 0 0 10px 0;
  /* background: #e4e4e4; */
  background: rgba(0, 71, 122, 0.1);
  /* box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25); */
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.outcome-content .title {
  font-family: "Calibri", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 49px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
  /* primary Header Color */

  color: #5A5F61;
}
.outcome-content .number {
  font-family: "Calibri", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 64px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
  /* primary Header Color */

  color: #00477a;
}
.pro-options-content.cont-height{
  height:84%;
}
</style>