<template>
  <div class="project-top">
        <div class="project-button d-flex justify-content-center">
          <li class="" style="list-style: none">
            <v-btn v-if="project[0].donors"
              id="donor"
              @click="setview($event)"
              class="btn mr-2"
              :style="{ backgroundColor: '#fff', color: '#000',fontWeight:'600' }"
              >Donors</v-btn
            >
          </li>
          <li style="list-style: none">
            <v-btn v-if="project[0].get_partners"
              id="partner"
              @click="setview($event)"
              class="btn ml-2"
              :style="{ backgroundColor: '#fff', color: '#000',fontWeight:'600' }"
              >Partners</v-btn
            >
          </li>
        </div>
      </div>
</template>

<script>
export default {
    props:{
        project: {},
    },
    methods:{
    setview(event)
    {
        let type = event.currentTarget.id;
        return (this.$emit('setprojectview',type))
    }
    }
}
</script>

<style>

</style>