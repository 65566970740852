<template>
  <div class="project-right">
        <ul>
          <li class="project-icon" v-if="project[0].partner_stories">
            <div class="pro">
              <a
                href="javascript:void(0)"
                id="organizer"
                @click="setview($event)"
                style="background-color: transparent; color: #fff"
              >
                <img
                  style="background-color: transparent; border-radius: 50%"
                  :src="require('@/assets/img/part_story.svg')"
                  alt=""
                />
              </a>
              <b-tooltip target="organizer" class="all-tooltip" triggers="hover">
                  Partner Stories
              </b-tooltip>
            </div>
          </li>
          <li class="project-icon" v-if="project[0].beneficiary_stories">
            <div class="pro">
              <a href="javascript:void(0)" id="beneficiary"
                @click="setview($event)">
                <img
                  style="background-color: transparent; border-radius: 50%"
                  :src="require('@/assets/img/ben_story.svg')"
                  alt=""
                />
              </a>
              <b-tooltip target="beneficiary" class="all-tooltip" triggers="hover">
                  Beneficiary Stories
              </b-tooltip>
            </div>
          </li>
        </ul>

        <Probubbles />
        
      </div>
</template>

<script>
export default {
    props:{
        project: {},
    },
    components:{
      Probubbles: () => import('../components/probubbles'),
    },
    methods:{
    setview(event)
    {
      let type = event.currentTarget.id;
        return (this.$emit('setprojectview',type))
    }
    }
}
</script>

<style>

</style>